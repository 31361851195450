import { Injectable, inject } from '@angular/core';
import { LeadPageModel, Pagination } from '@models';
import { createEffect, ofType } from '@ngrx/effects';
import { LeadService } from '@services/lead.service';
import { MatDialogRefStore } from '@services/matDialogRefStore.service';
import { selectOrganization } from '@stores/auth/auth.selector';
import { setErrorToast } from '@stores/shared/shared.actions';
import { objectToFormData } from '@utils';
import { BaseEffect } from '@utils/BaseEffect';
import dayjs from 'dayjs';
import {
  EMPTY,
  catchError,
  combineLatest,
  exhaustMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import leadActions from './lead.action';
import leadSelector from './lead.selector';

@Injectable()
export class LeadEffect extends BaseEffect {
  private service = inject(LeadService);
  private matDialogRefStore = inject(MatDialogRefStore);

  fetchLead$ = createEffect(() => {
    return this.action$.pipe(
      ofType(leadActions.fetchLead),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(leadSelector.selectPaginationAndFilter),
        ])
      ),
      exhaustMap(([_, [organization, { filter, pagination }]]) => {
        return this.service
          .get({
            filter: this.convertToFilter(filter, pagination),
            organizationId: organization.id,
          })
          .pipe(
            exhaustMap(data => of(leadActions.fetchLeadSuccess({ data }))),
            catchError(() =>
              of(setErrorToast({ message: 'Unable to fetch lead try again.' }))
            )
          );
      })
    );
  });

  sendMessage$ = createEffect(() => {
    return this.action$.pipe(
      ofType(leadActions.sendMessage),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([{ data, ref }, organization]) => {
        return this.service
          .sendMessage(
            objectToFormData({
              ...data,
              organizationId: organization.id,
            })
          )
          .pipe(
            exhaustMap(() => {
              this.toastrService.success('Message sent successfully');
              ref && this.matDialogRefStore.close(ref);
              return EMPTY;
            }),
            catchError(err =>
              of(
                setErrorToast({
                  message:
                    err?.error?.message ?? 'Unable to send message try again.',
                })
              )
            )
          );
      })
    );
  });
  exportLead$ = createEffect(() => {
    return this.action$.pipe(
      ofType(leadActions.exportToCsv),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(leadSelector.selectFilter),
        ])
      ),
      exhaustMap(([_, [organization, filter]]) =>
        this.service
          .exportToCsv(organization.id, {
            filter: this.convertToFilter(filter),
          })
          .pipe(
            exhaustMap(response => {
              if (window.confirm('Do you want to save leads?')) {
                this.service.saveFile(
                  response,
                  `leads-${dayjs().format('YYYY-MM-DD hh:mm:ss A')}.csv`
                );
              }
              return EMPTY;
            }),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Some thing error occurred try again!.',
                })
              )
            )
          )
      )
    );
  });

  addToGroup$ = createEffect(() => {
    return this.action$.pipe(
      ofType(leadActions.assignLeads),
      exhaustMap(({ data }) =>
        this.service.addToGroup(data.ids, data.groupId).pipe(
          exhaustMap(() => {
            this.toastrService.success('Lead Added successfully.');
            return EMPTY;
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Unable to add lead try again.' }))
          )
        )
      )
    );
  });
  fetchInterest$ = createEffect(() => {
    return this.action$.pipe(
      ofType(leadActions.fetchInterest),
      withLatestFrom(combineLatest([this.store.select(selectOrganization)])),
      exhaustMap(([_, [organization]]) => {
        return this.service.getInterest(organization.id).pipe(
          switchMap(({ dropper: data }) =>
            of(leadActions.fetchInterestSuccess({ data }))
          ),
          catchError(() =>
            of(
              setErrorToast({ message: 'Unable to load Interest.Try Again!.' })
            )
          )
        );
      })
    );
  });

  private convertToFilter(
    filter: LeadPageModel.filter,
    pagination?: Pagination
  ) {
    const where: Record<string, unknown> = {};
    if (filter.source) {
      where['source'] = { inq: filter.source };
    }
    if (filter.interest && filter.interest.length > 0) {
      where['interest'] = { inq: filter.interest };
    }
    if (filter.dateRange) {
      where['createdDate'] = this.getDateFilter(filter.dateRange);
    }
    if (filter.visitorId) {
      where['visitorId'] = filter.visitorId;
    }
    const condition: Record<string, unknown> = { where };
    if (filter.order) {
      condition['order'] =
        `${filter.order.field} ${filter.order.direction.toUpperCase()}`;
    } else {
      condition['order'] = `createdDate DESC`;
    }
    if (pagination) {
      condition['page'] = pagination.page;
      condition['limit'] = pagination.offset;
    }
    if (filter.leads && filter.leads.length > 0) {
      where['id'] = { inq: filter.leads };
    }

    if (filter.search) {
      const searchQuery = `.*${filter.search}.*`;
      if (!where['or']) {
        where['or'] = [];
      }
      (where['or'] as Array<any>).push(
        {
          fullname: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          visitorId: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          description: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          interest: {
            like: searchQuery,
            options: 'i',
          },
        }
      );
    }
    return condition;
  }
}
